export default class Region {
  code: string
  name: string
  is_updatable: boolean
  gar_tables: Array<string>

  constructor (
    code: string,
    name: string,
    is_updatable: boolean,
    gar_tables: Array<string>
  ) {
    this.code = code
    this.name = name
    this.is_updatable = is_updatable
    this.gar_tables = gar_tables
  }
}
